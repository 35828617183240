import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* USER DEFINED COMPONENTS */
import Input from "../../components/input";
import LoadingButton from "../../components/button";

/* ICONS */
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

import { useFormik } from "formik";
import * as yup from "yup";

/* ASSETS */
import Logo from "../../assets/images/logo.png";

/* API IMPORT */
import { adminRegister } from "../../service/api";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup
    .string()
    .email()
    .required("Email is required."),
  password: yup.string().required("Password is required."),
});

const AuthLogin = () => {
  // GLOBAL STATES
  const navigate = useNavigate();

  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);

  // FORMIK REGISTRATION FUNCTION
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      adminRegister(values)
        .then((res) => {
          console.log("res", res);
          if (res?.status) {
            toast.success(res?.message);
            navigate("/");
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log("er", e?.response?.data?.message);
          if (e?.response?.data?.message) {
            toast.error(e?.response?.data?.message);
          } else {
            toast.error(e?.message);
          }
        })
        .finally((res) => {
          setLoading(false);
        });
    },
  });

  return (
    <section className="bg-authBg">
      {/* AUTH FORM */}
      <div className="h-authForm">
        <div className="container h-full flex items-center justify-center">
          <div className="w-[60vh] bg-white rounded-lg border border-authBorder p-10">
            <img src={Logo} className="w-32 h-auto" alt="Lohana Mahaparishad" />
            <div className="mt-5">
              <h1 className="text-2xl font-proxima-bold text-black">
                Admin Portal Registration
              </h1>
              <h2 className="text-lg font-proxima-regular text-greyText mt-1">
                Access your e-commerce dashboard and manage your orders and
                online store via this dashboard
              </h2>
            </div>

            <form className="mt-5" onSubmit={formik.handleSubmit}>
              <div className="flex gap-3">
                {/* FIRST NAME */}
                <div className="form-group w-full">
                  <label className="text-sm font-proxima-semibold text-black">
                    First Name <span className="text-red-500">*</span>
                    <div className="mt-1">
                      <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={
                          formik.errors.firstName && formik.touched.firstName
                            ? true
                            : false
                        }
                      />
                    </div>
                  </label>
                </div>
                {/* LAST NAME */}
                <div className="form-group w-full">
                  <label className="text-sm font-proxima-semibold text-black">
                    Last Name <span className="text-red-500">*</span>
                    <div className="mt-1 relative">
                      <Input
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={
                          formik.errors.lastName && formik.touched.lastName
                            ? true
                            : false
                        }
                      />
                    </div>
                  </label>
                </div>
              </div>

              {/* EMAIL ADDRESS */}
              <div className="form-group mt-5">
                <label className="text-sm font-proxima-semibold text-black">
                  Email address <span className="text-red-500">*</span>
                  <div className="mt-1">
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.errors.email && formik.touched.email
                          ? true
                          : false
                      }
                    />
                  </div>
                </label>
              </div>
              {/* PASSWORD */}
              <div className="form-group mt-5">
                <label className="text-sm font-proxima-semibold text-black">
                  Password <span className="text-red-500">*</span>
                  <div className="mt-1 relative">
                    <Input
                      type={togglePassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.errors.password && formik.touched.password
                          ? true
                          : false
                      }
                    />
                    <Button
                      className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5 !absolute top-2 right-2"
                      onClick={() => setTogglePassword(!togglePassword)}
                    >
                      {togglePassword ? (
                        <IoMdEyeOff className="w-5 h-5 text-greyText" />
                      ) : (
                        <IoMdEye className="w-5 h-5 text-greyText" />
                      )}
                    </Button>
                  </div>
                </label>
              </div>

              <LoadingButton type="submit" label="Register" loading={loading} />
            </form>
            <p className="text-sm font-proxima-semibold text-black mt-3 text-center">
              Already have an account?{" "}
              <a className=" text-[#f3cd16]" href="/">
                Sign in instead
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <div className="py-3 border-t border-authBorder bg-white">
        <div className="container">
          <div className="flex items-center justify-between">
            <p className="text-sm font-proxima-regular text-black">
              Copyright 2024 © Lohana Mahaparishad. All rights reserved.
            </p>
            <p className="text-sm font-proxima-regular text-black">
              Designed by{" "}
              <a
                href="https://www.roundtechsquare.com"
                target="_blank"
                rel="noreferrer"
                className="text-sm font-proxima-semibold text-blue-500"
              >
                RoundTechSquare
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthLogin;
